import axios from "axios";
import { Link } from "gatsby";
import * as React from "react"
import CategoryTabs from "../../components/atoms/CategoryTabs";
import Header from "../../components/Header";
import SEO from "../../components/SEO";

import * as styles from './styles.module.scss'
import { ArrowIcon } from "../../components/atoms/Icons";

const tabs = [
  "All posts",
  "Cybersecurity",
  "Crypto",
  "Blockchain",
  "Insights",
]

const IndexPage = () => {
  const [activeTab, setActiveTab] = React.useState<string>("All posts")
  const [loading, setLoading] = React.useState<boolean>(false)
  const [bPosts, setBPosts] = React.useState([])
  const [categories, setCategories] = React.useState<Array<{ id: string; name: string; }>>([])

  React.useEffect(() => {
    setLoading(true)
    axios.get("https://blog.fntcyber.com/api/v1/articles")
      .then(result => {
        const data = result.data
        setBPosts(data.data)
        setLoading(false)
      })
      .catch(err => {
        console.log("====================================")
        console.log(`Something bad happened while fetching the data\n${err}`)
        console.log("====================================")
      })
    axios.get("https://blog.fntcyber.com/api/v1/categories")
      .then(result => {
        const data = result.data
        setCategories(data.data)
        setLoading(false)
      })
      .catch(err => {
        console.log("====================================")
        console.log(`Something bad happened while fetching the data\n${err}`)
        console.log("====================================")
      })
  }, [])

  return (
    <main>
      <Header />
      <div className={styles.container}>
        <div className={styles.top}>
          <h1>Blog and news</h1>
          <div className={styles.description}>
            Learn about cybersecurity, cryptocurrencies,  blockchain and discover mindful insights.
          </div>
        </div>

        <div className={styles.posts}>
          {false && <CategoryTabs tabs={tabs} activeTab={activeTab} selectTab={setActiveTab} />}
          {loading ? (
            <>
              <div className={styles.loadingSceleton}></div>
              <div className={styles.loadingSceleton}></div>
              <div className={styles.loadingSceleton}></div>
            </>
          ) : (
          <>
            {bPosts.map((post: any, index: number) => {
              return <div key={post.alias} className={styles.post}>
                <img src={post.title_image} className={styles.image} loading="lazy" alt="" />
                <div className={styles.content}>
                  <div className={styles.contentWrapper}>
                    <div className={styles.postInfo}>
                      <div className={styles.category}>{categories.find(({ id }) => id === post.category_id)?.name}</div>
                      <div className={styles.readTime}>{post.reading_time} min read</div>
                    </div>
                    <h2 className={styles.title}>{post.title}</h2>
                    <div className={styles.postDescription}>{post.preview}</div>
                  </div>
                  <Link to={post.alias} className={styles.readMore}>Read more <ArrowIcon /> </Link>
                </div>
              </div>
            })}
          </>
          )}
        </div>
      </div>
    </main>
  )
}

export default IndexPage

export const Head = () => (
  <SEO />
)