// extracted by mini-css-extract-plugin
export var category = "styles-module--category--WweE1";
export var container = "styles-module--container--qWZ9O";
export var content = "styles-module--content--b+7qk";
export var contentWrapper = "styles-module--contentWrapper--N41Ec";
export var description = "styles-module--description--h7bXi";
export var image = "styles-module--image--qZvNt";
export var loading = "styles-module--loading--NaxLe";
export var loadingSceleton = "styles-module--loadingSceleton--71v4a";
export var post = "styles-module--post--Wz4q+";
export var postDescription = "styles-module--postDescription--QB2hG";
export var postInfo = "styles-module--postInfo--i0u9e";
export var posts = "styles-module--posts--Sn-1R";
export var readMore = "styles-module--readMore--5QtOY";
export var readTime = "styles-module--readTime--6k-7-";
export var title = "styles-module--title--2ty5j";
export var top = "styles-module--top--rqTvY";